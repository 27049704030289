<template>
    <Setup-Step
        @yes="f => addNew()"
        @no="f => { f(); }"
        :hideYesNo="false"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="supplier-pricing"
        title="Price Tiers">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="supplier-price-tiers"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.priceTierName }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn small text :to="{ name: 'supplier-price-tier', params: { id: item.id }}" target="_blank">change <v-icon right small>mdi-open-in-new</v-icon></v-btn>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>
                    <p class="my-4">Would you like to add a price tier?</p>
                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        label="Tier Name"
                        v-model="newItem.priceTierName"
                        isEditing />
                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="loadTierItems" />
                </v-tab-item>

                <v-tab-item>
                    <p>What's the price for each product?</p>
                    
                    <div v-for="(item, index) in newItem.priceTierItems" :key="index">
                        <Price-Tier-Item-Blade
                            isEditing
                            :item="item">
                            <template v-slot="{ item }">
                                {{ item.product.productName }}
                            </template>
                        </Price-Tier-Item-Blade>
                        <v-divider class="my-1" />
                    </div>

                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Done"
                        @click="saveTier" />
                </v-tab-item>
            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Supplier-Price-Tier-Setup',
    components: {
        SetupStep: () => import('~home/setup/Setup-Step.vue'),
        PriceTierItemBlade: () => import('~home/price-tiers/Price-Tier-Item-Blade.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j) {
            this.newItem = j || { priceTierName: null, priceTierItems: [] };
            this.stage += 1;
        },
        openTier(tier) {
            var d = this.$router.resolve({ name: 'supplier-price-tier', query: { id: tier.id }});
            console.log(d);
            window.open(d.href, '_blank');
        },
        async saveTier() {
            try {
                this.loadingMsg = 'Saving';
                this.newItem.priceTierItems = this.newItem.priceTierItems.filter(x => x.valuePerRateFactor > 0 || (this.isLengthyArray(x.valueIncrements)));
                await this.$BlitzIt.store.post('supplier-price-tiers', this.newItem);
                this.stage = 0;
                this.refreshList = !this.refreshList;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async loadTierItems() {
            this.loadingMsg = 'Loading Products';
            var products = await this.$BlitzIt.store.getAll('products', { filterBy: 'Sold', properties: 'ID,SortNumber,ProductName,Abbreviation' });

            this.newItem.priceTierItems = products.map(product => {
                return {
                    productID: product.id,
                    product: product,
                    rateFactor: 'unit',
                    valuePerRateFactor: 0,
                    valueIncrements: [],
                    id: ''
                }
            })
            .orderBy(x => x.product.sortNumber);

            this.stage += 1;
            this.loadingMsg = null;
        }
    }
}
</script>